.img-thumb {
    border-radius: 4px !important;
    width: 145px !important; /* Set a small width */
height: 95% !important;
    margin: 0% !important;
    display: block;
    position: absolute;
    top: 4px !important;
    left: 2px !important;
    right: 2px !important;
    bottom: 2px !important;
    z-index: 1;

}

/*
img {
    border: 1px solid #ddd; 
    border-radius: 4px;  
    padding: 5px; 
    width: 150px; 
}*/

/* Add a hover effect (blue shadow) */
img:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}

.fileContainer {
    overflow: hidden;
    position: relative;
    cursor: pointer;
    color:#0073aa;
}

.fileContainer [type=file] {
    cursor: inherit;
    display: block;
    font-size: 999px;
    filter: alpha(opacity=0);
    min-height: 100%;
    min-width: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
}