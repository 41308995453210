.carousel-control-next {
    right: -22px;
}

.carousel-control-prev {
    left: -22px;
}
.alice-carousel__stage-item {
    width: auto !important;
}

.sample {
	margin-left: auto;
	margin-right: auto;
    width: 400px; 
    
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    min-width: 100%;
    background: #fbfafb;
    border-top: 0.0625rem solid #e1dee2;
    border-bottom: 0.0625rem solid #e1dee2;/**/
}

.edit-widget .elements-wrapper .actions-wrapper .actions .action {
    border-right: 0.0625rem solid #e1dee2;
    text-align: center !important;
    vertical-align: middle !important;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 8px;
}
.contenteditable{ height: auto !important}
.text-size{ width:35px; padding: 0 !important; display: inline-block; text-align: center}