.clip-me {
    /* Works! */
    -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
            clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);

  }
.img-thumb {
    border-radius: 4px !important;
    width: 145px !important; /* Set a small width */
height: 95% !important;
    margin: 0% !important;
    display: block;
    position: absolute;
    top: 4px !important;
    left: 2px !important;
    right: 2px !important;
    bottom: 2px !important;
    z-index: 1;

}

/*
img {
    border: 1px solid #ddd; 
    border-radius: 4px;  
    padding: 5px; 
    width: 150px; 
}*/

/* Add a hover effect (blue shadow) */
img:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}

.fileContainer {
    overflow: hidden;
    position: relative;
    cursor: pointer;
    color:#0073aa;
}

.fileContainer [type=file] {
    cursor: inherit;
    display: block;
    font-size: 999px;
    filter: alpha(opacity=0);
    min-height: 100%;
    min-width: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
}
.cell_input {
    width: auto !important;
    height: 100% !important;
    margin: 0px !important;
    border-radius: 0px !important;
    padding: 2px !important;
    max-width: 100% !important;
}


.table td, .table th {
     padding: 0px !important;
}
.table{
    margin: 0px !important;
}
.carousel-control-next {
    right: -22px;
}

.carousel-control-prev {
    left: -22px;
}
.alice-carousel__stage-item {
    width: auto !important;
}

.sample {
	margin-left: auto;
	margin-right: auto;
    width: 400px; 
    
    display: -webkit-flex; 
    
    display: flex;
    -webkit-align-items: stretch;
            align-items: stretch;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    min-width: 100%;
    background: #fbfafb;
    border-top: 0.0625rem solid #e1dee2;
    border-bottom: 0.0625rem solid #e1dee2;/**/
}

.edit-widget .elements-wrapper .actions-wrapper .actions .action {
    border-right: 0.0625rem solid #e1dee2;
    text-align: center !important;
    vertical-align: middle !important;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 8px;
}
.contenteditable{ height: auto !important}
.text-size{ width:35px; padding: 0 !important; display: inline-block; text-align: center}
.bs-popover-top {
    margin-bottom: 17px !important;
}

/*
.react-draggable {
    border: 1px dashed transparent;
} 
.react-draggable:hover {
    border: 1px dashed #2ba8e0;
} 
*/

.react-draggable > div:hover:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px dashed #2ba8e0;
} 
.react-draggable  ul li{
     list-style-type: disc  ;
     list-style-position: inside  ;
} 
.react-draggable  ol li{
   
    list-style-position: inside  ;
    list-style-type: decimal  ;
} 


.card{
    min-height: 400px
}
.card-body {
    min-height: 400px;
    border: solid 1px rgb(177, 171, 171);
    padding: 0px
}

.rnd-class{
    padding: 0px;
}
.ui-resizable-handle {
    position: absolute;
    font-size: .1px;
    display: block;
    border: 2px solid #fff;
    border-radius: 12px;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.3);
    background-color: #2ba8e0;
    width: 15px !important;
    height: 15px !important;
    cursor: default;
}

.ui-resizable-nw {
    left: -8px;
    top: -8px;
    cursor: nw-resize;
}

.ui-resizable-ne {
    right: -8px;
    top: -8px;
    cursor: ne-resize;
}
.ui-resizable-sw {
    left: -8px;
    bottom: -8px;
    cursor: sw-resize;
}
.ui-resizable-se {
    right: -8px;
    bottom: -8px;
    cursor: se-resize;
}

.ui-resizable-e {
    right: -8px !important;
    top: 50% !important;
    margin-top: -8px;
    cursor: e-resize;
}

.ui-resizable-w {
    left: -8px !important;
    top: 50% !important;
    margin-top: -8px;
    cursor: w-resize;
}

.ui-resizable-n {
    left: 50% !important;
    top: -8px !important;
    margin-left: -8px !important;
    cursor: n-resize;
}

.ui-resizable-s {
    left: 50% !important;
    bottom: -8px !important;
    margin-left: -8px !important;
    cursor: s-resize;
}
.card{
    border: 1px solid #bdc3c7;
    padding: 10px;
    height: auto;
    min-height: auto
}

.card-body {
    /* min-height: 400px; */
    /* border: solid 1px rgb(177, 171, 171); */
    padding: 0px;
    height: auto;
    min-height: auto;
    border: none;
}

.card-link {
    color: #bdc3c7;
}
.slide-pane__overlay {
    position: fixed;
    top: 110px;
    left: 81px;
    right: 0px;
    bottom: 0px;
    width: 20rem;
    background-color: rgba(0,0,0,0);
}

.slide-pane .slide-pane__title {
    font-family: "NHaasGroteskTXPro-65Md", sans-serif;
    font-size: 14px;
}



.slide-pane__close {
   
    right: 1.875rem;
    padding: 16px;
    opacity: 0.7;
    cursor: pointer;
}
.wrapper-contenteditable{ position: relative; margin-right: -20px; padding-right: 20px}
.wrapper-contenteditable:hover .delete-contenteditable{ display: block}
.delete-contenteditable{    -webkit-animation: fadeIn 0.6s ease-in;    animation: fadeIn 0.6s ease-in; position: absolute; right: 0; top: 10px; display: none; padding: 0; background: none; border:none}
.flex-doc > div{ margin-bottom: 30px}
.flex-doc > div:nth-child(3n){ margin-right: 0 !important}
.flex-doc > div:nth-child(3n+1){  clear: both;}
.recover_padd{padding-right: 15px !important; padding-left: 15px !important;}
.marge_link_edit{ margin-right: 10px}
@-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
