.card{
    border: 1px solid #bdc3c7;
    padding: 10px;
    height: auto;
    min-height: auto
}

.card-body {
    /* min-height: 400px; */
    /* border: solid 1px rgb(177, 171, 171); */
    padding: 0px;
    height: auto;
    min-height: auto;
    border: none;
}

.card-link {
    color: #bdc3c7;
}