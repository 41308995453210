.card{
    min-height: 400px
}
.card-body {
    min-height: 400px;
    border: solid 1px rgb(177, 171, 171);
    padding: 0px
}

.rnd-class{
    padding: 0px;
}
.ui-resizable-handle {
    position: absolute;
    font-size: .1px;
    display: block;
    border: 2px solid #fff;
    border-radius: 12px;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.3);
    background-color: #2ba8e0;
    width: 15px !important;
    height: 15px !important;
    cursor: default;
}

.ui-resizable-nw {
    left: -8px;
    top: -8px;
    cursor: nw-resize;
}

.ui-resizable-ne {
    right: -8px;
    top: -8px;
    cursor: ne-resize;
}
.ui-resizable-sw {
    left: -8px;
    bottom: -8px;
    cursor: sw-resize;
}
.ui-resizable-se {
    right: -8px;
    bottom: -8px;
    cursor: se-resize;
}

.ui-resizable-e {
    right: -8px !important;
    top: 50% !important;
    margin-top: -8px;
    cursor: e-resize;
}

.ui-resizable-w {
    left: -8px !important;
    top: 50% !important;
    margin-top: -8px;
    cursor: w-resize;
}

.ui-resizable-n {
    left: 50% !important;
    top: -8px !important;
    margin-left: -8px !important;
    cursor: n-resize;
}

.ui-resizable-s {
    left: 50% !important;
    bottom: -8px !important;
    margin-left: -8px !important;
    cursor: s-resize;
}