.bs-popover-top {
    margin-bottom: 17px !important;
}

/*
.react-draggable {
    border: 1px dashed transparent;
} 
.react-draggable:hover {
    border: 1px dashed #2ba8e0;
} 
*/

.react-draggable > div:hover:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px dashed #2ba8e0;
} 
.react-draggable  ul li{
     list-style-type: disc  ;
     list-style-position: inside  ;
} 
.react-draggable  ol li{
   
    list-style-position: inside  ;
    list-style-type: decimal  ;
} 

