.cell_input {
    width: auto !important;
    height: 100% !important;
    margin: 0px !important;
    border-radius: 0px !important;
    padding: 2px !important;
    max-width: 100% !important;
}


.table td, .table th {
     padding: 0px !important;
}