.slide-pane__overlay {
    position: fixed;
    top: 110px;
    left: 81px;
    right: 0px;
    bottom: 0px;
    width: 20rem;
    background-color: rgba(0,0,0,0);
}

.slide-pane .slide-pane__title {
    font-family: "NHaasGroteskTXPro-65Md", sans-serif;
    font-size: 14px;
}



.slide-pane__close {
   
    right: 1.875rem;
    padding: 16px;
    opacity: 0.7;
    cursor: pointer;
}
.wrapper-contenteditable{ position: relative; margin-right: -20px; padding-right: 20px}
.wrapper-contenteditable:hover .delete-contenteditable{ display: block}
.delete-contenteditable{    animation: fadeIn 0.6s ease-in; position: absolute; right: 0; top: 10px; display: none; padding: 0; background: none; border:none}
.flex-doc > div{ margin-bottom: 30px}
.flex-doc > div:nth-child(3n){ margin-right: 0 !important}
.flex-doc > div:nth-child(3n+1){  clear: both;}
.recover_padd{padding-right: 15px !important; padding-left: 15px !important;}
.marge_link_edit{ margin-right: 10px}
@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }